import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;
$(document).ready(function() {
	// Slider
	$('.c-slider').click(function() {
		console.log($(this).siblings('.dots-container'));
	});
	$('.c-slider').each(function() {
		$(this).slick({
			infinite: false,
			slidesToShow: 3,
			slidesToScroll: 1,
			dots: true,
			arrows: false,
			appendDots: $(this).siblings('.dots-container'),
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 760,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});
	});

	// Smooth scroll
	$(document).on('click', 'a[href^="#"]', function(event) {
		event.preventDefault();

		$('html, body').animate(
			{
				scrollTop: $($.attr(this, 'href')).offset().top
			},
			500
		);
	});

	var videos = $('.c-movie-partial__video');

	$('.c-movie-partial__slider').on('afterChange', function() {
		videos.each(function() {
			if ($(this).hasClass('player')) {
				$(this)
					.find('figure')
					.children('iframe')
					.remove();
				$(this).removeClass('player');
			}
		});
	});

	// Show thumbnail instead of iframe
	var isDragging = false;
	var startingPos = [];
	$('.c-movie-partial__video:not(.player)')
		.mousedown(function(evt) {
			isDragging = false;
			startingPos = [evt.pageX, evt.pageY];
		})
		.mousemove(function(evt) {
			if (
				!(evt.pageX === startingPos[0] && evt.pageY === startingPos[1])
			) {
				isDragging = true;
			}
		})
		.mouseup(function() {
			if (isDragging) {
			} else {
				var that = $(this);
				setTimeout(function() {
					var YTid = that.data('filmUrl');
					const videoType = that.data('videoType');

					if (videoType === 'vimeo') {
						that.addClass('player')
							.find('figure')
							.append(
								'<iframe width="940" height="529" src="https://player.vimeo.com/video/' +
									YTid +
									'?autoplay=1&mute=1&enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>'
							);
					} else {
						that.addClass('player')
							.find('figure')
							.append(
								'<iframe width="940" height="529" src="https://www.youtube.com/embed/' +
									YTid +
									'?autoplay=1&mute=1&enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>'
							);
					}
					$(window).resize();
				}, 400);
			}
			isDragging = false;
			startingPos = [];
		});

	const openBtn = $('#open-button');
	openBtn.click(function() {
		toggleMenu();
	});

	function toggleMenu() {
		$('body').toggleClass('menu-fixed');
		openBtn.toggleClass('open');
	}

	// Menu on escape
	$(document).keyup(function(e) {
		if (e.keyCode === 27) {
			if ($('body').hasClass('menu-fixed')) {
				$('body').toggleClass('menu-fixed');
				$('#open-button').toggleClass('open');
			}
		}
		if (e.keyCode === 77 && !$('input').is(':focus')) {
			if ($('body').hasClass('menu-fixed')) {
				$('body').toggleClass('menu-fixed');
				$('#open-button').toggleClass('open');
			} else {
				$('body').toggleClass('menu-fixed');
				$('#open-button').toggleClass('open');
			}
		}
	});
	$('iframe').each(function() {
		var url = $(this).data('filmUrl');
		console.log(url);

		$(this).attr('src', url);
	});

	// When the window is resized
	$(window)
		.resize(function() {
			// Find all YouTube videos
			var $allVideos = $(
				"iframe[src*='//player.vimeo.com'], iframe[src*='//www.youtube.com']"
			);

			// The element that is fluid width
			var $fluidEl = $allVideos.parents('figure');

			// Figure out and save aspect ratio for each video
			$allVideos.each(function() {
				$(this)
					.data('aspectRatio', this.height / this.width)

					// and remove the hard coded width/height
					.removeAttr('height')
					.removeAttr('width');
			});

			var newWidth = $fluidEl.width();

			// Resize all videos according to their own aspect ratio
			$allVideos.each(function() {
				var $el = $(this);
				$el.width(newWidth).height(newWidth * $el.data('aspectRatio'));
			});

			// Kick off one resize to fix all videos on page load
		})
		.resize();

	$('h1').each(function() {
		var string = $(this).html();
		string = string.replace(/ ([^ ]*)$/, '&nbsp;$1');
		$(this).html(string);
	});
});
