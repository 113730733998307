import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;
var data = window.question_data;
jQuery(function($) {
	$(document).ready(function() {
		var activeSlide = 0;
		var questions = $('.question');
		var campaigns = [];
		var firstQuestion = $('.question')[0];

		$('body').on('load', function() {
			$('.c-test__question-content').height(
				$(firstQuestion).outerHeight()
			);
		});
		$(firstQuestion).animate({ left: '0', opacity: 1 }, 300);

		// Add listeners to answers
		$('.answer').each(function() {
			this.addEventListener('click', function() {
				$('.c-test__progress-bar-inner').css(
					'max-width',
					((activeSlide + 1) / (questions.length - 1)) * 100 + '%'
				);
				data[activeSlide].points.forEach(point => {
					point.campaign.forEach(campaign => {
						var score = 0;
						var maxScore = 0;
						var minScore = 0;

						if (campaigns[campaign.id]) {
							score = campaigns[campaign.id].score;
							maxScore = campaigns[campaign.id].maxScore;
							minScore = campaigns[campaign.id].minScore;
						}

						var pnames = [
							'heltUenig',
							'littUenig',
							'littEnig',
							'heltEnig'
						];
						var max = parseInt(point[pnames[0]], 10);
						var min = parseInt(point[pnames[0]], 10);
						pnames.forEach(e => {
							var v = parseInt(point[e], 10);
							if (v > max) max = v;
							if (v < min) min = v;
						});

						campaigns[campaign.id] = {
							object: campaign,
							score: score + parseInt(point[$(this).val()], 10),
							maxScore: maxScore + max,
							minScore: minScore + min
						};

						console.log(campaigns);
					});
				});

				nextSlide();
			});
		});

		function nextSlide() {
			activeSlide++;
			$(questions[activeSlide]).addClass('active');
			$('.c-test__question-content').height(
				$(questions[activeSlide]).outerHeight()
			);

			$(questions[activeSlide - 1]).animate(
				{ left: '-100%', opacity: 1 },
				300
			);
			$(questions[activeSlide]).animate({ left: '0', opacity: 1 }, 300);

			$(questions[activeSlide - 1]).removeClass('active');
		}

		$('#see-test-result').click(function() {
			$('.question-container').css('background-color', '#32BF7C');

			$('.VT-top-results').html(
				campaigns
					.sort((a, b) => {
						if (calcPercent(a) < calcPercent(b)) return 1;
						if (calcPercent(a) > calcPercent(b)) return -1;
						return 0;
					})
					.slice(0, 3)
					.map(
						c =>
							`<a class="u-clean" style="text-decoration: none;" href="${
								c.object.url
							}"><div class="c-test__question-content-container" style="margin-top: 20px; display: flex"><span class="VT-matching-text"><p class="c-test__question-content-text">${
								c.object.name
							}</p><p class="VT-matching-score">${calcPercent(
								c
							)}% match</p></span>            
              <span class="VT-matching-arrow">                             
              <img src="/assets/img/arrow.svg">
              </span>
              </div></a>`
					)
			);

			if (typeof gtag === 'function') {
				gtag('event', 'Test fullført', {
					event_category: window.current_data,
					event_label: campaigns[0].title
				});
			}

			if (data.length > 2) {
				$('#question-main-title').html('Topp 3 retninger');
			} else {
				$('#question-main-title').html('Ditt resultat:');
			}

			$(questions[activeSlide]).animate(
				{ left: '-100%', opacity: 1 },
				300
			);
			$('.result').animate({ left: '0', opacity: 1 }, 300);
			$(questions[activeSlide]).removeClass('active');
			$('.result').addClass('show');

			function calcPercent(obj) {
				console.log(Math.abs(obj.minScore));
				return parseInt(
					(Math.abs(obj.score) /
						(obj.maxScore + Math.abs(obj.minScore))) *
						100,
					10
				);
			}
		});
	});
});
